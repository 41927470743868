<template>
  <div class="SuperComboboxItemIndicator">
    <Icon :icon="mapIcon.check" :size="14" :stroke-width="2" />
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss">
.SuperComboboxItemIndicator {
  position: absolute;
  left: 0;
  width: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  color: var(--gray-2);
}
</style>
